<template>
    <div class="card">
        <div class="card-body">
            <div v-if="loading" class="alert alert-info text-center"><i class="fas fa-spinner fa-spin"></i></div>
            <template v-else>
                <div v-if="locations.length === 0" class="alert alert-info text-center">Пусто</div>
                <div v-else>
                    <div>
                        <h4>Сводка</h4>
                        <div class="table-responsive pl-5">
                            <table class="table table-sm table-bordered">
                                <thead>
                                <tr>
                                    <th>Номенклатура</th>
                                    <th class="text-right">Кол-во</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="nomenclature in nomenclatures">
                                    <td>{{nomenclature.nomenclature.name}}</td>
                                    <td class="text-right">{{nomenclature.count}}</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td class="text-right">Итого:</td>
                                    <td class="text-right">{{totalCount}}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <hr>
                    <span class="pull-right">Всего: {{locations.length}}</span>
                    <div v-for="location in locations">
                        <h4>{{location.type_name}} <b>{{location.name}}</b></h4>
                        <span class="pull-right">Ящиков: {{location.stockItems.length}}</span>
                        <div class="table-responsive pl-5">
                            <table class="table table-bordered table-sm">
                                <thead>
                                <tr>
                                    <th>Номенклатура</th>
                                    <th>Ящик</th>
                                    <th class="text-right">Кол-во</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="location.stockItems.length === 0">
                                    <td colspan="3">
                                        <div class="alert alert-info text-center">Пусто</div>
                                    </td>
                                </tr>
                                <tr v-for="item in location.stockItems">
                                    <td>{{item.entity.nomenclature.name}}</td>
                                    <td>{{item.entity.box.barcode.code}}</td>
                                    <td class="text-right">{{item.entity.count}}</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colspan="2" class="text-right">Итого:</td>
                                    <td class="text-right">{{sumBy(location.stockItems, i => parseInt(i.entity.count))}}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                        <hr>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import {STOCK_ENDPOINT} from "../../utils/endpoints";
    import {sumBy} from 'lodash';

    export default {
        name: "ReadyToShip",
        data() {
            return {
                loading: false,
                locations: [],
            };
        },
        computed: {
            nomenclatures() {
                let result = {};
                this.locations.map(p => {
                    p.stockItems.map(i => {
                        if (!!result[i.entity.nomenclature.id]) {
                            result[i.entity.nomenclature.id].count += parseInt(i.entity.count);
                        } else {
                            result[i.entity.nomenclature.id] = {
                                nomenclature: i.entity.nomenclature,
                                count: parseInt(i.entity.count)
                            };
                        }
                    });
                });

                return result;
            },
            totalCount() {
                let result = 0;
                this.locations.map(p => {
                    p.stockItems.map(i => {
                        result += parseInt(i.entity.count);
                    });
                });

                return result;
            },
        },
        methods: {
            sumBy,
            load() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                this.$http.get(STOCK_ENDPOINT + '/not_empty_locations', {
                    params: {
                        without_loading: true,

                        location_type: 'pallet',
                        with: [
                            'stockItems.entity.box.barcode',
                            'stockItems.entity.nomenclature.measureUnit'
                        ]
                    }
                }).then(response => {
                    this.locations = response.data;
                }).catch(response => {
                    this.$toast.error(response.data.message);
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        mounted() {
            this.load();
        }
    }
</script>

<style scoped>

</style>
